<template>
  <div class="flagged-users">
    <users-list-table
      :users="users"
      :meta="listMeta"
      :links="listLinks"
      :loading="loading"
      @loadMore="loadMore"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import UsersListTable from '../components/UsersListTable'

export default {
  name: 'AllUsersList',

  components: {
    UsersListTable,
  },

  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.setFilter({
      ...this.filter,
      type: 'flagged',
    })

    if (!this.filter.search) {
      this.clearUsers()
      this.fetchUsers()
    }
  },

  computed: {
    ...mapState({
      users: (state) => state.users.list,
      listMeta: (state) => state.users.listMeta,
      listLinks: (state) => state.users.listLinks,
      filter: (state) => state.users.filter,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  methods: {
    ...mapActions({
      getUsers: 'users/getUsers',
    }),

    ...mapMutations({
      clearUsers: 'users/clearUserList',
      setFilter: 'users/setFilter',
    }),

    loadMore() {
      if (this.listLinks.next) {
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },

    async fetchUsers(page = 1) {
      if (this.loading) return

      let params = { ...this.filter, page }
      this.loading = true
      await this.getUsers(params)
      this.loading = false
    },
  },
}
</script>
